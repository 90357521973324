var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"game-easy"}},[_c('games-topic',{attrs:{"topic":"抽獎樂","src":"game/easy/roulette","type":"left","feature":"可自行設計 Gif 動圖，在動圖尺寸內自由變化遊戲視覺。例如：扭蛋機、夾娃娃機等....機率與數量可自由設定。","applys":[
  '適用任何即抽即中的活動',
  '不侷限任何產業皆可使用',
  '可抽出折價券序號做線上、線下兌換']}}),_c('games-topic',{attrs:{"topic":"刮刮樂","src":"game/easy/scratch","type":"right","feature":"仿傳統刮刮樂的手勢動作，直接運用在手機上可用指腹與手機版面擦取，而得到廠商們設定的機率與數量，由於此為隨機結果，也可用在測驗上。例如：刮出肌膚年紀、刮出你未來小孩模樣。","applys":[
  '隨機性的心理測驗',
  '即刮即中的活動結果',
  '粉絲團經營小遊戲',
  '不侷限任何產業皆可使用',
  '可刮出折價券序號做線上、線下兌換']}}),_c('games-topic',{attrs:{"topic":"點點趣","src":"game/easy/click","type":"left","feature":"是一個大人、小孩都喜愛的遊戲類型，類似打地鼠的概念。畫面顯示圖示與炸彈可將其打掉，但打到炸彈則失敗。圖示的顯示秒數可自由控制。結果可兌換折價券或禮物。","applys":[
  '展場互動',
  '粉絲團經營小遊戲',
  '餐廳用餐時，大人小孩得到樂趣',
  '媽媽在沙龍洗髮時，可讓孩子玩樂',
  '門市逛街時，店員與客人的互動']}}),_c('games-topic',{attrs:{"topic":"轉輪盤","src":"game/easy/spinner","type":"right","feature":"將傳統的大轉盤改成數位性，轉盤內的禮物會依照給獎的模式作為披薩分割，獎項內容、機率、數量可自行設定。","applys":[
  '適用任何即轉即中的活動',
  '不侷限任何產業皆可使用',
  '隨機性的心理測驗',
  '可抽出折價券序號做線上、線下兌換']}}),_c('games-topic',{attrs:{"topic":"瘋拉霸","src":"game/easy/slot","type":"left","feature":"拉霸機台的體驗畫面，讓玩家啟動拉霸機。產生獎品連線或不連線而決定是否得到獎項拉霸小圖可自行上傳，機率數量自行設置。","applys":[
  '適用任何即轉即中的活動',
  '不侷限任何產業皆可使用',
  '隨機性的心理測驗',
  '可抽出折價券序號做線上、線下兌換']}}),_c('games-topic',{attrs:{"topic":"問答趣","src":"game/easy/qanda","type":"right","feature":"此模組可使用為，問答測驗或問券調查。問答測驗如果設定題目有對與錯的結果問卷調查可知道品牌與服務的滿意度稽核。","applys":[
  '餐廳用餐的滿意度調查',
  '服務人員服務的滿意度調查',
  '產品與服務的滿意度調查',
  '品牌與服務的相關問題']}}),_c('games-topic',{attrs:{"topic":"翻牌趣","src":"game/easy/card","type":"left","feature":"此為大人、小孩皆愛玩的機制型遊戲。在有限的時間內翻出對子，在時間內闖關成功著可拿小禮物或折價券。","applys":[
  '展場互動',
  '粉絲團經營小遊戲',
  '餐廳用餐時，大人小孩得到樂趣',
  '媽媽在沙龍洗髮時，可讓孩子玩樂',
  '門市逛街時，店員與客人的互動']}}),_c('games-topic',{attrs:{"topic":"心理測驗","src":"game/easy/psychologic","type":"right","feature":"可依據測驗結果，設定題目的關聯度強弱。讓系統依據題目強弱來判斷最終結果。","applys":[
  '粉絲團經營小遊戲',
  '餐廳用餐時，大人小孩得到樂趣',
  '串連產品與測驗間的相關連得到品牌連結']}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }