var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"funnel"},[_c('div',{staticClass:"mainBody"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_c('font',[_vm._v("增加轉換"),_c('br'),_vm._v("Conversions")])],1)]),_vm._m(6),_c('div',{staticClass:"steps s1"},[_c('span',{staticClass:"step"},[_vm._v("第 "),_c('font',[_vm._v("1")]),_vm._v(" 階段")],1)]),_c('div',{staticClass:"steps s2"},[_c('span',{staticClass:"step"},[_vm._v("第 "),_c('font',[_vm._v("2")]),_vm._v(" 階段")],1)]),_c('div',{staticClass:"steps s3"},[_c('span',{staticClass:"step"},[_vm._v("第 "),_c('font',[_vm._v("3")]),_vm._v(" 階段")],1)]),_c('div',{staticClass:"steps s4"},[_c('span',{staticClass:"step"},[_vm._v("第 "),_c('font',[_vm._v("4")]),_vm._v(" 階段")],1)]),_c('div',{staticClass:"steps s5"},[_c('span',{staticClass:"step"},[_vm._v("第 "),_c('font',[_vm._v("5")]),_vm._v(" 階段")],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("建立品牌與鐵粉"),_c('br'),_vm._v("Build brands")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("增加品牌知名度"),_c('br'),_vm._v("Brand awareness")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("口碑建立與堆疊"),_c('br'),_vm._v("Word-of-mouth marketing")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("提高搜尋能見度"),_c('br'),_vm._v("Impressions")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("增加網站造訪"),_c('br'),_vm._v("Traffic")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("提高名單量"),_c('br'),_vm._v("Leads")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"bottom"},[_c('img',{attrs:{"src":require("@/assets/image/products/funnel/target.svg")}}),_vm._v("\n    針對客群，進行行銷\n  ")])
}]

export { render, staticRenderFns }