var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"g-media"}},[_vm._m(0),_c('h2',[_vm._v("多元廣告類型")]),_vm._m(1),_c('div',{staticClass:"thatSimple"},[_c('div',{staticClass:"vids"},[_c('div',{staticClass:"media m1",on:{"click":function($event){$event.stopPropagation();return _vm.playVid(1)}}},[_vm._m(2),_c('video',{attrs:{"src":require("../assets/image/service/mediaAds/GoogleAds/media/media1.mp4"),"muted":""},domProps:{"muted":true}})]),_c('div',{staticClass:"media m2",on:{"click":function($event){$event.stopPropagation();return _vm.playVid(2)}}},[_vm._m(3),_c('video',{attrs:{"src":require("../assets/image/service/mediaAds/GoogleAds/media/media2.mp4"),"muted":""},domProps:{"muted":true}})])]),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('img',{staticClass:"floating upper tablet",attrs:{"src":require("../assets/image/service/mediaAds/GoogleAds/media/contactTablet.png")}}),_c('div',{staticClass:"text"},[_c('h2',[_vm._v("透過接觸目標客群")]),_c('p',[_vm._v("Google Ads 多媒體廣告的放送範圍含括 200 多萬個網站和超過 65 萬個應用程式；無論目標對象在哪，您都能向他們放送廣告。")])]),_c('div',{staticClass:"lowerPart"},[_c('img',{staticClass:"floating lower phone",attrs:{"src":require("../assets/image/service/mediaAds/GoogleAds/media/contactPhone.png")}}),_c('img',{staticClass:"floating lower PC",attrs:{"src":require("../assets/image/service/mediaAds/GoogleAds/media/contactPC.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multiType"},[_c('div',{staticClass:"type"},[_c('h3',[_vm._v("文字廣告")]),_c('p',[_vm._v("多媒體廣告包含一行標題、兩行說明文字和一行網址，和大家常在 Google 搜尋上看到的廣告相同")])]),_c('div',{staticClass:"type"},[_c('h3',[_vm._v("Gmail 廣告")]),_c('p',[_vm._v("這類自訂廣告會在 Gmail 中顯示，使用者可儲存、轉寄以及在收件匣中展開廣告。")])]),_c('div',{staticClass:"type"},[_c('h3',[_vm._v("橫幅廣告")]),_c('p',[_vm._v("您可在這類圖像或互動式多媒體廣告中，添加自訂版面配置、互動元素和動畫等內容。")])]),_c('div',{staticClass:"type"},[_c('h3',[_vm._v("應用程式廣告")]),_c('p',[_vm._v("您可以製作指定特定行動應用程式類別的廣告活動，還能追蹤正在放送的應用程式內廣告成效。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mask paused"},[_c('i',{staticClass:"far fa-play-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mask paused"},[_c('i',{staticClass:"far fa-play-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slogan"},[_c('h2',[_vm._v("放送多媒體廣告就是這麼簡單！")]),_c('div',{staticClass:"steps"},[_c('div',{staticClass:"step"},[_c('div',{staticClass:"num"},[_vm._v("1")]),_c('div',{staticClass:"text"},[_c('h3',[_vm._v("選擇格式並製作廣告")]),_c('p',[_vm._v("無論是製作簡單的文字廣告，還是利用帳戶中的廣告圖庫打造圖像廣告，通通都只要幾分鐘就能完成。")])])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"num"},[_vm._v("2")]),_c('div',{staticClass:"text"},[_c('h3',[_vm._v("決定廣告刊登位置")]),_c('p',[_vm._v("根據您對目標客群的瞭解 (例如興趣喜好或客層)，打造精準的多媒體廣告活動。")])])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"num"},[_vm._v("3")]),_c('div',{staticClass:"text"},[_c('h3',[_vm._v("設定預算")]),_c('p',[_vm._v("決定您願意花多少錢來網羅目標對象，然後鎖定最可能對廣告訊息感興趣的客群進行宣傳。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contactIdeal"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"text"},[_c('h3',[_vm._v("接觸理想客戶")]),_c('p',[_vm._v("Google 多媒體廣告聯播網內包含數百萬個網站、新聞網頁和網誌，以及 Gmail 與 YouTube 等多個 Google 旗下網站，可接觸全球 90% 的網路使用者。只要善用指定目標、關鍵字、客層和再行銷等廣告相關設定，您就能把自家品牌推到客戶眼前，提高他們購買商品/服務的意願，進而讓過路客下手消費。")])]),_c('div',{staticClass:"chart chart1"},[_c('img',{attrs:{"src":require("../assets/image/service/mediaAds/GoogleAds/media/contactIdeal.png")}})])]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"text"},[_c('h3',[_vm._v("持續推升成效")]),_c('p',[_vm._v("您可以根據我們團隊提供的資料評估廣告成效，從中找出反應最好的客群。無論是想衝高業績或吸引更多客戶來電，我們團隊都能協助您評估廣告活動成效並進行調整，進而達成目標")])]),_c('div',{staticClass:"chart chart2"},[_c('img',{attrs:{"src":require("../assets/image/service/mediaAds/GoogleAds/media/keepIncrease.png")}})])])])
}]

export { render, staticRenderFns }