var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"range",on:{"mouseover":_vm.hover}},[_vm._m(0),_c('div',{staticClass:"bbl bubble1"},[_c('div',{staticClass:"topic"},[_vm._v("Creative")]),_c('font',[_vm._v("內容的有感與互動，都來自團隊們的創意，從品牌策略到生活結合，都可經營發想至做出非常有感的貼文")])],1),_vm._m(1),_c('div',{staticClass:"bbl bubble2"},[_c('div',{staticClass:"topic"},[_vm._v("Design")]),_c('font',[_vm._v("如果說創意是靈魂，設計就是外在軀殼，任何事物都需要有完整的視覺，自媒體的內容則可豐富化。視覺可分為：Gif、多圖排版、靜態圖片、極短片、手繪漫畫、實拍情境。")])],1),_vm._m(2),_c('div',{staticClass:"bbl bubble3"},[_c('div',{staticClass:"topic"},[_vm._v("Plan")]),_c('font',[_vm._v("在經營規劃的同時，團隊會與您溝通您的品牌策略，有計劃地排程何時何地的曝光，可隨時掌握品牌計劃。")])],1),_vm._m(3),_c('div',{staticClass:"bbl bubble4"},[_c('div',{staticClass:"topic"},[_vm._v("Film")]),_c('font',[_vm._v("影片呈獻已然成為時下經營社群媒體首當使用的素材方式，我們將影片分支如下"),_c('br'),_vm._v("2D 小動畫"),_c('br'),_vm._v("創意短片")])],1),_vm._m(4),_c('div',{staticClass:"bbl bubble5"},[_c('div',{staticClass:"topic"},[_vm._v("Advertising")]),_c('font',[_vm._v("無論經營 FB 或其他自媒體，可藉由些許的廣告經費讓內容觸及更廣。這其中包含讚廣告、貼文互動、影片觀看...等。")])],1),_vm._m(5),_c('div',{staticClass:"bbl bubble6"},[_c('div',{staticClass:"topic"},[_vm._v("Blog")]),_c('font',[_vm._v("為了增加網站 SEO ，針對品牌與服務官方網站內，新增了 Blog 文章："),_c('br'),_vm._v("1. 可加強自然搜尋優化"),_c('br'),_vm._v("2. 強化消費者對品牌或服務的了解性")])],1),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"type1"},[_c('span',[_vm._v("創意內容")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"type2"},[_c('span',[_vm._v("視覺設計")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"type3"},[_c('span',[_vm._v("排程計劃")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"type4"},[_c('span',[_vm._v("影片拍攝")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"type5"},[_c('span',[_vm._v("廣告投遞")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"type6"},[_c('span',[_vm._v("Blog 文章")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weMedia"},[_c('div',{staticClass:"flip"}),_c('span',[_vm._v("We Media")])])
}]

export { render, staticRenderFns }