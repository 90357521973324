var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_vm._m(0),_c('span',{staticClass:"arrow a1"},[_c('font',{staticClass:"s1"})],1),_c('span',{staticClass:"arrow a2"},[_c('font',{staticClass:"s1"})],1),_vm._m(1),_vm._m(2),_c('span',{staticClass:"arrow a3"},[_c('font',{staticClass:"s2"})],1),_c('span',{staticClass:"arrow a4"},[_c('font',{staticClass:"s2"})],1),_vm._m(3),_c('span',{staticClass:"arrow a5"},[_c('font',{staticClass:"s3"})],1),_vm._m(4),_c('span',{staticClass:"arrow a6"},[_c('font',{staticClass:"s4"})],1),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user st1"},[_c('img',{attrs:{"src":require("@/assets/image/products/user.svg")}}),_c('span',[_vm._v("使用者")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onlineURL st2"},[_c('img',{attrs:{"src":require("@/assets/image/products/onlineURL.svg")}}),_c('span',[_vm._v("線上 URL (Online)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store st2"},[_c('img',{attrs:{"src":require("@/assets/image/products/store.svg")}}),_c('span',[_vm._v("實體門市")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expose st3"},[_c('img',{attrs:{"src":require("@/assets/image/products/expose.svg")}}),_c('span',[_vm._v("活動曝光")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"FBAuthorize st4"},[_c('img',{attrs:{"src":require("@/assets/image/products/FBAuthorize.svg")}}),_c('span',[_vm._v("登入 FB 認證")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fansPlay1 st5"},[_c('img',{attrs:{"src":require("@/assets/image/products/fansPlay1.svg")}}),_c('span',[_vm._v("Fans Play 玩粉絲")])])
}]

export { render, staticRenderFns }